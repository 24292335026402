import { CustomHttpService } from './../../services/custom-http.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import store from 'store'
import { environment } from './../../../environments/environment'
import { Router } from '@angular/router'
import * as moment from 'moment'

@Injectable()
export class userManagementService {
  constructor(private http: CustomHttpService, private router: Router) {}

  getEventLogList(payload): Observable<any> {
    return this.http.get(`Dashboard/EventLog/${payload}`)
  }
  getUserList(): Observable<any> {
    return this.http.get(`Identity/ListUsers`)
  }

  getRoleList(): Observable<any> {
    return this.http.get(`Identity/GetRolesList`)
  }

  getGroupList(): Observable<any> {
    return this.http.get(`Identity/GetGroupList`)
  }

  addUser(userData): Observable<any> {
    return this.http.post(`Identity/CreateUserWithPassword`, {
      UserRoleId: userData.RoleId,
      Username: userData.Username.toLowerCase(),
      Firstname: userData.FirstName,
      Lastname: userData.LastName,
      Mobile: userData.Mobile,
    })
  }

  updateGroup(groupData): Observable<any> {
    if (groupData.GroupId) {
      return this.http.put(`Identity/UpdateGroup`, {
        GROUP_ID: groupData.GroupId,
        GROUP_CODE: groupData.GroupCode,
        GROUP_NAME: groupData.GroupName,
        GROUP_DESC: groupData.GroupDescription,
      })
    } else {
      return this.http.post(`Identity/CreateGroup`, {
        GROUP_CODE: groupData.GroupCode,
        GROUP_NAME: groupData.GroupName,
        GROUP_DESC: groupData.GroupDescription,
      })
    }
  }

  deleteGroup(groupData): Observable<any> {
    return this.http.delete(`Identity/DeleteGroup/${groupData}`)
  }

  updateUser(userData): Observable<any> {
    return this.http.post(`Identity/UpdateUserInfoById`, {
      USER_ID: userData.UserId,
      FIRSTNAME: userData.FirstName,
      LASTNAME: userData.LastName,
      ROLE_ID: userData.RoleId,
      MOBILE: userData.Mobile.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(
        /\s+/g,
        '',
      ),
      TITLE: userData.Title,
      BADGENUMBER: userData.BadgeNumber,
      DOB: userData.Dob ? moment(userData.Dob).format('YYYY-MM-DD') : '',
      STATUS: userData.Status,
    })
  }

  deleteUser(userData): Observable<any> {
    return this.http.delete(`Identity/DeleteUser/${userData}`)
  }

  getModuleList(): Observable<any> {
    return this.http.get(`Identity/GetAllSubModuleList`)
  }

  addRole(roleData): Observable<any> {
    return this.http.post(`Identity/CreateRoles`, {
      ROLE_NAME: roleData.RoleName,
      ROLE_DESC: roleData.RoleDescription,
      ROLE_GROUP_ID: roleData.GroupId,
      MODULE_PERMISSION_CODE: roleData.ModulePermission,
    })
  }

  updateRole(roleData): Observable<any> {
    return this.http.put(`Identity/UpdateRoles`, {
      ROLE_ID: roleData.RoleId,
      ROLE_NAME: roleData.RoleName,
      ROLE_DESC: roleData.RoleDescription,
      ROLE_GROUP_ID: roleData.GroupId,
      MODULE_PERMISSION_CODE: roleData.ModulePermission,
    })
  }

  deleteRole(roleData): Observable<any> {
    return this.http.delete(`Identity/DeleteRoles/${roleData}`)
  }
  addPermission(data): Observable<any> {
    return this.http.put(`Identity/AddRolesModulePermissionBatch`, {
      RoleId: data.RoleId,
      ModuleId_PermissionCode: data.ModulePermission,
    })
  }
  operatorResetPassword(username): Observable<any> {
    return this.http.post(`Identity/OperatorForgotPassword`, {
      Username: username,
    })
  }
}
