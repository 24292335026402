import { ConfigService } from './config.service'
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { from, Observable } from 'rxjs'
import { LocalService } from './local.service'
import { MtCrypto } from './mt-crypto'
import {
  map,
  switchMap,
  catchError,
  withLatestFrom,
  concatMap,
  switchMapTo,
  timeout,
} from 'rxjs/operators'
import { Router } from '@angular/router'

declare type Headers = { [k: string]: string | number }
declare type Params = { [k: string]: string | number } | object
declare type RequestOptions = { headers?: Headers; params?: Params; responseType?: any }
const TIMEOUT = 1000 * 30
@Injectable({
  providedIn: 'root',
})
export class CustomHttpService {
  httpOptions: any
  private with_credentials
  private pentest: boolean = true
  Data: []
  metadata
  settings = {
    USERNAME_LABEL: '',
    PASSWORD_LABEL: '',
    PASSWORD_MIN_CHAR: '',
    PASSWORD_MAX_CHAR: '',
    PASSWORD_AL_NUMBER: '',
    PASSWORD_AL_CAP_LETTER: '',
    PASSWORD_AL_SML_LETTER: '',
    PASSWORD_AL_SPL_CHAR: '',
    SESSION_TIMEOUT: '',
    MAX_ATTEMP: '',
    USER_LOCK_TIMEOUT: '',
    DAILY_LIMIT_PASSWORD: '',
    MULTI_SESSION: '',
    ALLOW_SELF_WHITELIST: '',
  }
  constructor(
    private http: HttpClient,
    public config: ConfigService,
    private localService: LocalService,
    private crypto: MtCrypto,
    private router: Router,
  ) {
    this.metadata = this.config.getMetadataConfig()
  }

  private stringify(object: Headers | Params): { [k: string]: string } {
    const result = {}
    for (const obj in object) {
      obj !== undefined && obj !== null && (result[obj] = object[obj].toString())
    }

    return result
  }

  public withCredentials(status?: boolean) {
    this.with_credentials = typeof status != 'undefined' ? status : true
    return this
  }

  private async setBasicHeaders(
    body: any,
    options: RequestOptions,
  ): Promise<{
    headers: { [k: string]: string }
    params: { [k: string]: string }
  }> {
    //get access token
    let session = this.localService.getJsonValue('SessionId')

    //set headers
    let headers = {
      'Content-Type': 'application/json',
    }

    headers = {
      ...headers,
      ...options.headers,
    }

    let params = options.params

    return { headers: this.stringify(headers), params: this.stringify(params) }
  }

  async encryption(url, body, options?, req_method?, postParams?) {
    const endpoint = `${this.config.getConfig().endPoint}`
    let session = this.localService.getJsonValue('SessionId')
    let data = {
      HttpRequest: req_method,
      MethodPath: url,
      BaseUrl: endpoint + '/',
      SessionId: session ? session : '',
      Parameter: postParams,
    }
    let IV = await this.getIV()
    let encrypted_data = this.crypto.encryptJson(data, this.config.getConfig().API_KEY, IV.iv)
    url = 'Security/Service/Request'
    body = {}
    options = {
      responseType: 'text',
      headers: {
        ...options.headers,
        EncryptedRequest: encrypted_data,
        IVToken: IV.iv,
      },
    }
    return { url, body, options, data }
  }

  async getIV() {
    let iv_token: any = this.localService.getJsonValue('iv_token')
    if (iv_token) {
      return iv_token
    }

    let url = `${this.config.getConfig().endPoint}/Security/Authentication/IV`
    let data = {
      ClientId: this.config.getConfig().ENCRYPTION_ID,
      ClientSecretKey: this.config.getConfig().ENCRYPTION_SECRET,
      GrantType: 'IVPlusTokenId',
    }
    let options = {}
    let params = {}
    let headers = {
      ...data,
    }
    iv_token = await this.http
      .get(url, {
        ...options,
        headers: headers,
        params: new HttpParams({ fromObject: params }),
      })
      .pipe(timeout(TIMEOUT))
      .toPromise()
      .then((response: any) => {
        return response.Data
      })
    this.localService.setJsonValue('iv_token', iv_token)
    return iv_token
  }
  isJsonString(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }
  encryptProcess(
    url: string,
    options: RequestOptions = {},
    req_method: string,
    postParams?,
  ): Observable<any> {
    this.metadata = this.config.getMetadataConfig()
    if (
      this.metadata.client == 'graton' ||
      this.metadata.client == 'gli-lnw' ||
      this.metadata.client == 'bmm-casinotrac' ||
      this.metadata.client == 'casinotrac' ||
      this.metadata.client == 'bmm-acres'
    )
      this.pentest = false

    return from(
      (async () => {
        let debug = { request: null, response: null }
        let { headers, params } = await this.setBasicHeaders({}, options)

        if (this.pentest) {
          let result = await this.encryption(url, params, options, req_method, postParams)
          debug.request = {
            original: {
              url,
              params,
              options,
              data: result.data,
            },
            encrypted: result,
          }
          url = result.url
          params = result.body
          options = result.options
          headers = result.options.headers
        }

        const endpoint = `${this.config.getConfig().endPoint}/${url}`
        let request: Promise<any> = this.http
          .get(endpoint, {
            ...options,
            headers: headers,
            params: new HttpParams({ fromObject: params }),
          })
          .pipe(timeout(TIMEOUT))
          .toPromise()
          .then(async (response: string) => {
            let data: any
            if (this.pentest) {
              let IV = await this.getIV()
              data = this.crypto.decryptJson(response, this.config.getConfig().API_KEY, IV.iv)
              debug.response = data
              let msg = data.Message
              if (!data.isSuccess) {
                // if (msg == 'Token is expired.' || msg == 'Invalid token') {
                //   this.localService.setJsonValue('iv_token', '')
                // }
                if (
                  msg == 'Invalid session.' ||
                  msg == 'Invalid session. Please specify session.'
                ) {
                  // console.log('encryptProcess success')
                  this.router.navigate(['auth/login'])
                }
                return Promise.reject(
                  new HttpErrorResponse({
                    error: data,
                    headers: new HttpHeaders(),
                    url: debug.request.original.url,
                  }),
                )
              }
            }
            return data
          })
          .catch(async request => {
            let errmsg = this.isJsonString(request.error)
              ? JSON.parse(request.error)
              : request.error
            if (errmsg.Message == 'Token is expired.' || errmsg.Message == 'Invalid token') {
              this.localService.setJsonValue('iv_token', '')
            }
            if (
              errmsg.Message == 'Invalid session.' ||
              errmsg.Message == 'Invalid session. Please specify session.'
            ) {
              // console.log('encryptProcess err')
              this.router.navigate(['auth/login'])
            }
            return Promise.reject(
              new HttpErrorResponse({
                error: errmsg,
                headers: new HttpHeaders(),
                url: debug.request.original.url,
              }),
            )
          })
        return request
      })(),
    )
  }

  get(api, options = {}) {
    this.sessionAlive()
    const url = `${this.config.getConfig().endPoint}/${api}`
    if (this.pentest) return this.encryptProcess(api, options, 'GET')
    else {
      return this.http.get(`${url}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          SessionId: this.localService.getJsonValue('SessionId')
            ? this.localService.getJsonValue('SessionId')
            : '',
        }),
        ...options,
      })
    }
  }
  getv2(api, options = {}) {
    this.sessionAlive()
    const url = `${this.config.getConfig().endPoint}/${api}`
    return this.http.get(`${url}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        SessionId: this.localService.getJsonValue('SessionId')
          ? this.localService.getJsonValue('SessionId')
          : '',
      }),
      ...options,
    })
  }
  postv2(api, body, options = {}) {
    this.sessionAlive()
    const url = `${this.config.getConfig().endPoint}/${api}`
    return this.http.post(`${url}`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        SessionId: this.localService.getJsonValue('SessionId')
          ? this.localService.getJsonValue('SessionId')
          : '',
      }),
      ...options,
    })
  }
  post(api, body, options = {}) {
    this.sessionAlive()
    const url = `${this.config.getConfig().endPoint}/${api}`
    if (this.pentest) return this.encryptProcess(api, options, 'POST', body)
    else {
      return this.http.post(`${url}`, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          SessionId: this.localService.getJsonValue('SessionId')
            ? this.localService.getJsonValue('SessionId')
            : '',
        }),
        ...options,
      })
    }
  }
  put(api, body, options = {}) {
    this.sessionAlive()
    const url = `${this.config.getConfig().endPoint}/${api}`
    if (this.pentest) return this.encryptProcess(api, options, 'PUT', body)
    else {
      return this.http.put(`${url}`, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          SessionId: this.localService.getJsonValue('SessionId')
            ? this.localService.getJsonValue('SessionId')
            : '',
        }),
        ...options,
      })
    }
  }
  patch(api, body, options = {}) {
    this.sessionAlive()
    const url = `${this.config.getConfig().endPoint}/${api}`
    if (this.pentest) return this.encryptProcess(api, options, 'PATCH', body)
    else {
      return this.http.patch(`${url}`, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          SessionId: this.localService.getJsonValue('SessionId')
            ? this.localService.getJsonValue('SessionId')
            : '',
        }),
        ...options,
      })
    }
  }
  delete(api, options = {}) {
    this.sessionAlive()
    const url = `${this.config.getConfig().endPoint}/${api}`
    if (this.pentest) return this.encryptProcess(api, options, 'DELETE', '')
    else {
      return this.http.delete(`${url}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          SessionId: this.localService.getJsonValue('SessionId')
            ? this.localService.getJsonValue('SessionId')
            : '',
        }),
        ...options,
      })
    }
  }
  postBlob(api, body, options = {}) {
    this.sessionAlive()
    const url = `${this.config.getConfig().endPoint}/${api}`
    return this.http.post(`${url}`, body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        SessionId: this.localService.getJsonValue('SessionId')
          ? this.localService.getJsonValue('SessionId')
          : '',
      }),
      responseType: 'blob',
      ...options,
    })
  }

  sessionAlive() {
    let setting = this.localService.getJsonValue('settings')
    let settings = this.isJsonString(setting) ? JSON.parse(setting) : setting
    // console.log(settings,this.localService.getJsonValue('SessionId'),this.localService.getJsonValue('session_expiry'))

    if (
      this.localService.getJsonValue('SessionId') &&
      settings &&
      this.localService.getJsonValue('session_expiry') != null &&
      this.localService.getJsonValue('session_expiry') > +new Date().getTime()
    ) {
      let dateTimerInterval = parseFloat(settings['SESSION_TIMEOUT']) * 60 * 1000
      let lastTime = new Date().getTime()
      this.localService.setJsonValue('session_expiry', lastTime + dateTimerInterval)
    }
  }
}
