import { Component } from '@angular/core'
import { ConfigService } from 'src/app/services/config.service'
import { reportService } from 'src/app/services/report'

@Component({
  selector: 'cui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  metadata
  currentYear: number = new Date().getFullYear()

  version
  constructor(public config: ConfigService, private report: reportService) {
    this.metadata = this.config.getMetadataConfig()
    this.getVersion()
  }

  getVersion() {
    return this.report.releaseVersion().subscribe(async (data: any) => {
      this.version = 'v' + data.data[0].BUILD
    })
  }
}
